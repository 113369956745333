.banner {
  float: left;
  width: 100%;
  height: calc(100% - 82px);

  .outer-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 134px;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      padding-bottom: 160px;
    }
  }

  .content {
    color: $white;
    float: left;
    width: 100%;
    max-width: 300px;

    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }

  h1 {
    margin: 9px 0 0;
    font-weight: 700;
  }

  .btn-scroll {
    position: absolute;
    cursor: pointer;
    bottom: 54px;
    left: calc(50% - 24px);
    width: 48px;

    @include media-breakpoint-up(xl) {
      bottom: 71px;
      left: calc(50% - 29px);
      width: 58px;
    }
  }

  &.center {
    .content {
      text-align: center;
    }
  }
}
