header {
  padding: 22px 0;
  z-index: 1;
  position: relative;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  .logo {
    img {
      width: 89px;
    }
  }
}
