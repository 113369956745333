// bootstrap overrides
$primary: #dc0032;
$secondary: #414042;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/scss/variables";
@import "./assets/scss/fonts";
@import "./components/Header/index";
@import "./components/Banner/index";
@import "./components/Cards/index";
@import "./components/HexagonContent/index";
@import "./components/Testimonial/index";
@import "./pages/Index/index";
@import "../node_modules/animate.css/animate.css";

#root {
  position: relative;
}

a {
  color: $primary;
}

body {
  font-family: map-get($font, "pluto");
  font-weight: map-get($font-weight, "regular");
  letter-spacing: 0.3px;
  color: $secondary;
  line-height: 1.2;
}

.container {
  height: 100%;
  padding: 0 30px;

  @include media-breakpoint-up(lg) {
    padding: 0 15px;
  }
}

hr {
  background: rgba(0, 0, 0, 0.1);
  margin: 18px 0 27px;
}

// headings
h1 {
  font-weight: map-get($font-weight, "light");
  margin: 0;
  line-height: 1.2;
  font-size: 48px;

  @include media-breakpoint-up(lg) {
    font-weight: map-get($font-weight, "bold");
    font-size: 56px;
  }
}

h2 {
  font-weight: map-get($font-weight, "extraLight");
  font-size: 22px;
  letter-spacing: 0.3px;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
    letter-spacing: -0.4px;
  }
}

h3 {
  font-weight: map-get($font-weight, "bold");
  letter-spacing: -0.04em;
  margin: 0;
  font-size: 31px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: 36px;
    text-align: left;
  }
}

h4 {
  font-size: 22px;
  font-weight: map-get($font-weight, "light");

  @include media-breakpoint-up(lg) {
    font-size: 23px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 24px;
  }
}

h5 {
  text-transform: uppercase;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: map-get($font-weight, "medium");

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    font-weight: map-get($font-weight, "regular");
  }
}

h6 {
  color: $primary !important;
  text-transform: uppercase;
  font-weight: map-get($font-weight, "regular");

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

// button
.btn {
  font-weight: map-get($font-weight, "medium");
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, .2);
  white-space: nowrap;
  padding: 9px 29px;

  img {
    margin: 0 0 0 9px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 0 14px;
    }
  }
}

section {
  padding: 66px 0;

  @include media-breakpoint-up(lg) {
    padding: 81px 0;
  }
}

.top-content {
  background: $secondary;
  color: $white;
}

ul {
  padding: 0;
}

li {
  font-family: map-get($font, "roboto");
  font-weight: map-get($font-weight, "regular");
  font-size: 18px;
  background: url("./assets/images/tick.svg") no-repeat;
  list-style: none;
  padding: 0 0 0 35px;
  background-size: 19px;
  background-position: 2px 5px;
  line-height: 1.5;
  margin: 0 0 13px;

  &:last-of-type {
    margin: 0;
  }
}

.hero-section {
  position: relative;
  height: 685px;

  @media (min-width: 1450px) {
    height: 799px;
  }
}

// TO DO: delete
// .overlay {
//   margin: 0 auto;
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   opacity: 0.5;
//   z-index: 9999999999;
//   top: -1397px;
//   display: none;
// }

// .container {
//   width: 1210px;
// }
