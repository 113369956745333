@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@use "sass:map";

// font weight
$font-weight: (
  "robotoLight": 300,
  "extraLight": 350,
  "light": 370,
  "regular": 400,
  "medium": 500,
  "bold": 700,
  "extraBold": 800,
);

// font family
$font: (
  "roboto": "Roboto",
  "pluto": "plutoSans",
);
