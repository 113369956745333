.cards {
  background: radial-gradient(50% 135.73% at 50% 61.33%, $primary 0%, #39001f 100%);
  position: relative;
  padding: 0;

  .img-wrapper {
    position: absolute;
    height: 100%;
    width: 50%;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }

  .person1-bg {
    position: relative;
    height: 350px;
    background: url("../../assets/images/person1.png") no-repeat;
    background-position: right bottom;
    width: 100%;
    background-size: contain;
    float: left;

    @include media-breakpoint-up(lg) {
      background-position: -10px 50px;
      height: 100%;
    }

    @include media-breakpoint-up(xl) {
      background-position: 10px 50px;
    }

    @include media-breakpoint-up(xxl) {
      background-position: 70px 50px;
    }

    @media (min-width: 1600px) {
      background-position: 180px 50px;
    }
  }

  .person2-bg {
    position: relative;
    height: 350px;
    background: url("../../assets/images/person2.png") no-repeat;
    background-position: left bottom;
    width: 100%;
    background-size: contain;
    float: right;

    @include media-breakpoint-up(lg) {
      background-position: calc(100% - -160px) 50px;
      height: 100%;
    }

    @include media-breakpoint-up(xl) {
      background-position: calc(100% - -103px) 50px;
    }

    @include media-breakpoint-up(xxl) {
      background-position: calc(100% - -60px) 50px;
    }

    @media (min-width: 1600px) {
      background-position: calc(100% - 150px) 50px;
    }
  }

  .images {
    text-align: center;

    img:nth-of-type(1) {
      width: 47%;
      margin: -10px -12px 0 5px;
    }

    img:nth-of-type(2) {
      width: 53%;
      margin: 0 0 0 -13px;
    }
  }

  .outer-container {
    padding: 66px 30px;

    @include media-breakpoint-up(lg) {
      padding: 73px 15px;
    }
  }

  .content {
    display: block;
    margin: -73px 0 0;

    @include media-breakpoint-up(sm) {
      margin: -133px 0 0;
    }

    @include media-breakpoint-up(md) {
      margin: -183px 0 0;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }

  .card {
    border: 1px solid #e6e7e8;
    border-radius: 16px;
    width: 100%;
    box-shadow:
      0 24px 38px rgba(0, 0, 0, .14),
      0 9px 46px rgba(0, 0, 0, .12),
      0 11px 15px rgba(0, 0, 0, .2);

    @include media-breakpoint-up(lg) {
      width: 573px;
    }
  }

  .primary-content {
    padding: 24px;
    color: $black;

    @include media-breakpoint-up(lg) {
      padding: 48px 48px 24px;
    }
  }

  .secondary-content {
    padding: 24px;
    background-color: #f7f7f7;
    border-radius: 0 0 16px 16px;

    @include media-breakpoint-up(lg) {
      padding: 24px 48px 48px;
    }
  }

  h3 {
    margin: 0 0 27px;
    color: $black;
  }

  h6 {
    font-weight: map-get($font-weight, "medium");
    font-family: map-get($font, "roboto");
    margin-bottom: 19px;
  }

  p {
    font-size: 18px;
    font-weight: map-get($font-weight, "regular");
    font-family: map-get($font, "roboto");
    margin-bottom: 20px;
  }

  ul {
    margin: 0 0 28px;
  }

  .btn-anchor {
    margin-left: 50%;
    display: inline-block;
    transform: translateX(-50%);
  }
}
