.hexagon-content {
  padding: 66px 0;

  @include media-breakpoint-up(lg) {
    padding: 80px 0;
  }

  .row-container {
    display: flex;
    align-items: center;
    padding: 0 0 64px;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      padding: 0 0 76px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    .image-container {
      order: 1;

      @include media-breakpoint-up(lg) {
        order: 0;
      }
    }

    .text-container {
      order: 2;

      @include media-breakpoint-up(lg) {
        order: 0;
      }
    }
  }

  h1 {
    color: $black !important;
    font-weight: map-get($font-weight, "extraLight");
    font-size: 32px;

    @include media-breakpoint-up(lg) {
      font-size: 56px;
    }
  }

  hr {
    height: 4px;
    background: $primary;
    opacity: 1;
    border: 0;
    width: 160px;
    margin: 14px 0 23px 2px;

    @include media-breakpoint-up(lg) {
      margin: 16px 0 24px 2px;
    }
  }

  ul {
    padding-left: 28px;
    margin-bottom: 23px;
  }

  li {
    color: #111827;
    line-height: 1.5;
    margin: 0 0 12px;
    background: none;
    list-style: disc;
    padding: 0;
    font-family: map-get($font, "pluto");
    font-weight: map-get($font-weight, "light");
  }

  .image-container img {
    width: 100%;
    margin-bottom: 48px;

    @include media-breakpoint-up(lg) {
      width: 80%;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 71%;
    }
  }

  .content-container {
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 6px;
      margin-left: 12px;
    }
  }

  .btn-row {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }
}
