.index {
  .hero-section {
    background: url("../../assets/images/banner1.jpg") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .gradient-overlay {
    background:
      linear-gradient(180deg, rgba(0, 0, 0, 0.498) 4.72%, rgba(0, 0, 0, 0) 17.32%),
      linear-gradient(360deg, rgba(0, 0, 0, 0.64) 11.08%, rgba(0, 0, 0, 0) 52.69%);
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .top-content {
    text-align: center;

    p {
      font-size: 18px;
      font-weight: map-get($font-weight, "robotoLight");
      margin-top: 24px;
      margin-bottom: 0;
      line-height: 1.3;
      font-family: map-get($font, "roboto");

      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }
    }
  }

  .bottom-content {
    background: rgba(0, 0, 0, 0.87);
    color: $white;

    p {
      font-size: 24px;
      font-weight: map-get($font-weight, "robotoLight");
      margin-top: 23px;
      margin-bottom: 0;
      line-height: 1.2;
      font-family: map-get($font, "roboto");
    }

    h2 {
      font-size: 30px;

      @include media-breakpoint-up(lg) {
        font-size: 40px;
      }
    }
  }
}
