.testimonial {
  background: rgba(0, 0, 0, 0.05);
  color: #111827;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  h2 {
    font-weight: map-get($font-weight, "extraBold");
    font-size: 32px;
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      font-weight: map-get($font-weight, "medium");
      margin-bottom: 62px;
    }
  }

  h4 {
    margin-bottom: 21px;
    font-weight: map-get($font-weight, "medium");
  }

  p {
    font-size: 20px;
    font-weight: map-get($font-weight, "regular");
    font-family: map-get($font, "roboto");
    margin-bottom: 24px;
  }

  .padding-container {
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding: 0 15px;
    }
  }

  .btn-row {
    margin: 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin: 64px 0 0;
    }

    a {
      display: inline-block;
    }
  }

  img {
    margin-bottom: 42px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}
